import React, { useState } from "react";
import { Link } from "react-router-dom";
import { app } from "../config/firebase";
import { getAnalytics, logEvent } from "firebase/analytics";
import { FaBars } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import { SidebarData } from "../config/SidebarData";
import "../css/navbar.css";
import { SlArrowDown } from "react-icons/sl";
import LazyLoad from "react-lazyload";
const analytics = getAnalytics(app);
export default function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const [open, setOpen] = React.useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  const handleOpen = () => {
    setOpen(!open);
  };
  const handleMouseEnter = () => {
    setOpen(true);
  };
  const handleMouseLeave = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="navbar-container" style={{ overflowX: "hidden" }}>
        <LazyLoad>
          <Link to="/">
            <img
              className="logo"
              src="https://res.cloudinary.com/kcb-software-design/image/upload/v1713933861/KCB-WHITE-LOGO_evpmbp.png"
              alt="kcb software & design, software Vancouver, software company, web design, app design, web development, website development, SEO, algorithms, business software, IT"
            />
          </Link>
        </LazyLoad>
        <div className="menu-list hover">
          <Link
            to="/whoweare"
            className="menuItem hover"
            style={{ textDecoration: "none" }}
            onClick={logEvent(analytics, "WWA Click-header")}
          >
            Who We Are
          </Link>

          <div className="dropdown" onMouseEnter={handleMouseEnter}>
            <div className="menuItem " onClick={handleOpen}>
              Services <SlArrowDown style={{ fontSize: "10px" }} />
            </div>
            {open ? (
              <div
                className="dropdown-list"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Link
                  to="/services/webdev"
                  style={{ textDecoration: "none" }}
                  onClick={logEvent(analytics, "WebDev Click-header")}
                >
                  <div className="dropdown-item">Web Development</div>
                </Link>
                <Link
                  to="/services/seo"
                  style={{ textDecoration: "none" }}
                  onClick={logEvent(analytics, "SEO Click-header")}
                >
                  <div className="dropdown-item">SEO</div>
                </Link>
                <Link
                  to="/services/appdev"
                  style={{ textDecoration: "none" }}
                  onClick={logEvent(analytics, "AppDev Click-header")}
                >
                  <div className="dropdown-item">App Development</div>
                </Link>

                <Link
                  to="/services/automation"
                  style={{ textDecoration: "none" }}
                  onClick={logEvent(analytics, "Automation Click-header")}
                >
                  <div className="dropdown-item">Automation/Web Scraping</div>
                </Link>
              </div>
            ) : null}
          </div>
          <Link
            to="/contact"
            className="menuItem hover"
            style={{ textDecoration: "none" }}
          >
            Contact Us
          </Link>
        </div>
        <Link to="#" className="menu-bars lines">
          <FaBars style={{ color: "white" }} onClick={showSidebar} />
        </Link>
      </div>
      <div className="line-navbar"></div>
      <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        <ul className="nav-menu-items">
          <li className="navbar-toggle" onClick={showSidebar}>
            <Link to="#" className="menu-x">
              <IoCloseOutline
                onClick={showSidebar}
                style={{
                  color: "white",
                  fontSize: "40px",
                  alignSelf: "center",
                }}
              />
            </Link>
          </li>
          <div className="sideBarDataContainer">
            <div className="nav-container">
              <Link
                to="/whoweare"
                className="navItem"
                style={{ textDecoration: "none" }}
                onClick={showSidebar}
              >
                Who We Are
              </Link>
            </div>
            <br />

            <div className="navItem">Services</div>

            <div className="nav-service-container">
              <li>
                <Link
                  to="/services/webdev"
                  style={{ textDecoration: "none" }}
                  className="navItem-service"
                  onClick={showSidebar}
                >
                  Web Development
                </Link>
              </li>
            </div>
            <li>
              <div className="nav-service-container">
                <li>
                  <Link
                    to="/services/seo"
                    style={{ textDecoration: "none" }}
                    className="navItem-service"
                    onClick={showSidebar}
                  >
                    SEO
                  </Link>
                </li>
              </div>
            </li>
            <div className="nav-service-container">
              <li>
                <Link
                  to="/services/appdev"
                  style={{ textDecoration: "none" }}
                  className="navItem-service"
                  onClick={showSidebar}
                >
                  App Development
                </Link>
              </li>
            </div>

            <li>
              <div className="nav-service-container">
                <Link
                  to="/services/automation"
                  style={{ textDecoration: "none" }}
                  className="navItem-service"
                  onClick={showSidebar}
                >
                  Automation/Web Scraping
                </Link>
              </div>
            </li>

            <div className="contact">
              <Link
                to="/contact"
                className="navItem"
                style={{ textDecoration: "none" }}
                onClick={showSidebar}
              >
                Contact Us
              </Link>
            </div>
          </div>
        </ul>
      </nav>
    </>
  );
}
