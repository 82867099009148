import * as firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAw8Z_QlVwUMd6YevcJRYPRcN3q5gTIl_c",
  authDomain: "kcb-software-new.firebaseapp.com",
  projectId: "kcb-software-new",
  storageBucket: "kcb-software-new.firebasestorage.app",
  messagingSenderId: "374240205511",
  appId: "1:374240205511:web:1d5fe06f31420536a4bce2",
  measurementId: "G-5X91F8SVV2",
};
export const app = firebase.initializeApp(firebaseConfig);
